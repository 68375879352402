import React from 'react';
import './main_nav.css';
import Logo from '../logo/logo';
import { NavLink, useNavigate } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import {
    activeService$,
    updateActiveService,
    user$,
} from '../../features/user/user_repository';
import { setCurrentPage } from '../../features/documents/documents_repository';

function MainNav() {
    const [user] = useObservable(user$);
    const [activeService] = useObservable(activeService$);
    const navigate = useNavigate();

    const handleChangeService = (event) => {
        localStorage.setItem('activeService', event.target.value);
        updateActiveService(user.services[event.target.value]);
        setCurrentPage(1);
        return navigate(`/`);
    };

    return (
        <nav className="main-nav">
            <section className="main-nav__section">
                <Logo />
            </section>

            {user?.isVerified && (
                <div className={'service-chooser'}>
                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'aggregate'}>
                            <select
                                value={user.services.findIndex(
                                    (service) =>
                                        service.service_id ===
                                        activeService.service_id
                                )}
                                className={'control'}
                                onChange={(ev) => handleChangeService(ev)}
                            >
                                {user.services.map((service, index) => (
                                    <option
                                        key={service.service_id}
                                        value={index}
                                    >
                                        {service.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
            )}
            <ul className="main-nav__section menu">
                <li>
                    <p className="menu__header">Knowledge</p>

                    <ul>
                        <li>
                            <NavLink
                                to="/directories"
                                className={({ isActive, isPending }) =>
                                    isPending
                                        ? 'menu__link link menu__link_pending'
                                        : isActive
                                          ? ' menu__link link menu__link_active'
                                          : 'menu__link'
                                }
                            >
                                Directories
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/integrations"
                                className={({ isActive, isPending }) =>
                                    isPending
                                        ? 'menu__link link menu__link_pending'
                                        : isActive
                                          ? ' menu__link link menu__link_active'
                                          : 'menu__link'
                                }
                            >
                                Integrations
                            </NavLink>
                        </li>
                    </ul>
                </li>

                <li>
                    <p className="menu__header">Reports</p>

                    <ul>
                        <li>
                            <NavLink
                                to="/questions"
                                className={({ isActive, isPending }) =>
                                    isPending
                                        ? 'menu__link link menu__link_pending'
                                        : isActive
                                          ? ' menu__link link menu__link_active'
                                          : 'menu__link'
                                }
                            >
                                Questions
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="/usage"
                                className={({ isActive, isPending }) =>
                                    isPending
                                        ? 'menu__link link menu__link_pending'
                                        : isActive
                                          ? ' menu__link link menu__link_active'
                                          : 'menu__link'
                                }
                            >
                                Usage
                            </NavLink>
                        </li>
                    </ul>
                </li>

                {/*<li>*/}
                {/*    <p className="menu__header">Settings</p>*/}

                {/*    <ul>*/}
                {/*        <li>*/}
                {/*            /!*<a className="menu__link link" href="">*!/*/}
                {/*            /!*    Help & support{' '}*!/*/}
                {/*            /!*</a>*!/*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            /!*<a className="menu__link link" href="">*!/*/}
                {/*            /!*    Settings*!/*/}
                {/*            /!*</a>*!/*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
            </ul>
        </nav>
    );
}

export default MainNav;
