import { createStore, select, withProps } from '@ngneat/elf';
import {
    selectAllEntities,
    setEntities,
    withEntities,
} from '@ngneat/elf-entities';

export const DOCUMENTS_STORE_NAME = 'user';

const documentsStore = createStore(
    { name: DOCUMENTS_STORE_NAME },
    withEntities(),
    withProps({
        activeDocument: null,
        pages: 0,
        isLoading: false,
        currentPage: 1,
    })
);

export const activeDocument$ = documentsStore.pipe(
    select((state) => state.activeDocument)
);
export const pages$ = documentsStore.pipe(select((state) => state.pages || 1));
export const documents$ = documentsStore.pipe(selectAllEntities());
export const isActiveDocumentLoading$ = documentsStore.pipe(
    select((state) => state.isLoading)
);
export const currentPage$ = documentsStore.pipe(
    select((state) => state.currentPage)
);

export function setDocuments(documents) {
    documentsStore.update(setEntities(documents.list));
    documentsStore.update((state) => ({
        ...state,
        pages: documents.pages + 1,
    }));
}

export function setActiveDocument(activeDocument) {
    documentsStore.update((state) => ({
        ...state,
        activeDocument,
    }));
}

export function setIsLoading(isLoading) {
    documentsStore.update((state) => ({
        ...state,
        isLoading,
    }));
}

export function setCurrentPage(currentPage) {
    documentsStore.update((state) => ({
        ...state,
        currentPage,
    }));
}
