import React from 'react';
import './loading_screen.css';
import gandyLogo from '../../img/gandy_logo.svg';
import { Link } from 'react-router-dom';
import SlidingText from '../../components/sliding_text/sliding_text';

export function LoadingScreen() {
    const texts = [
        "sensing if you're worthy to enter...",
        'casting spell to unlock the entrance...',
        'opening magic doors...',
        'lightning up all the lamps...',
        'chasing away the spiders...',
        'looking for your knowledge...',
        'Ha, got it! Come in... there is much to be shared...',
    ];

    return (
        <div className="loading-screen">
            <Link className={'logo-link'} to={`/`}>
                <figure className="logo">
                    <img
                        src={gandyLogo}
                        height="60"
                        alt="Gandy Administration Panel"
                    />
                    Gandy.ai
                </figure>
            </Link>
            <br />
            <br />
            <div className={'loader'}></div>
            <br />
            <br />
            <SlidingText texts={texts} interval={2500} />
        </div>
    );
}
