import React, { useEffect, useState } from 'react';
import { getQuestionsStatsRaport } from '../../features/raports/questions_raport_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
    Colors,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { directories$ } from '../../features/directories/directories_repository';
import { getDirectories } from '../../features/directories/directories_service';

ChartJS.register(
    Colors,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function QuestionsUsage() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [questions, setQuestions] = useState([]);
    const [directories] = useObservable(directories$);
    const [date, onChange] = useState([
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ]);

    useEffect(() => {
        const fetchData = async () => {
            const questionStatsRaportData = await getQuestionsStatsRaport(
                {
                    serviceId: activeService.service_id,
                    params: {
                        date_from: date[0].toISOString(),
                        date_to: date[1].toISOString(),
                    },
                },
                token
            );

            setQuestions(questionStatsRaportData);

            if (activeService) {
                if (directories.length <= 0) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        };

        if (activeService) {
            fetchData();
        }
    }, [activeService, date, token]);

    const [aggregate, setAggregate] = useState('2');
    const [directoryId, setDirectoryId] = useState('');

    const submitForm = async (ev) => {
        ev.preventDefault();

        const params = {
            date_from: date[0].toISOString(),
            date_to: date[1].toISOString(),
            aggregate,
        };

        if (directoryId) {
            params.directory_id = directoryId;
        }

        const questionStatsRaportData = await getQuestionsStatsRaport(
            {
                serviceId: activeService.service_id,
                params,
            },
            token
        );

        setQuestions(questionStatsRaportData);
    };

    return (
        <div>
            <section className="section">
                <header className="section__header">
                    <h2 className="section__title">Questions usage raport</h2>
                </header>

                <form onSubmit={(ev) => submitForm(ev)} className={'form'}>
                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'date-range'}>
                            Select date
                            <DateRangePicker
                                id={'date-range'}
                                onChange={onChange}
                                value={date}
                            />
                        </label>
                    </div>

                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'aggregate'}>
                            Present as
                            <select
                                className={'control'}
                                onChange={(ev) => {
                                    setAggregate(ev.target.value);
                                }}
                                id={'aggregate'}
                                value={aggregate}
                            >
                                <option value={2}>DAILY</option>
                                <option value={3}>WEEKLY</option>
                                <option value={4}>MONTHLY</option>
                            </select>
                        </label>
                    </div>

                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'aggregate'}>
                            Directory
                            <select
                                className={'control'}
                                onChange={(ev) => {
                                    setDirectoryId(ev.target.value);
                                }}
                                id={'aggregate'}
                            >
                                <option value={''}>All</option>
                                {directories.map((directory) => (
                                    <option
                                        key={directory.id}
                                        value={directory.id}
                                    >
                                        {directory.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <button className={'btn'} type={'submit'}>
                        Show
                    </button>
                </form>

                <div style={{ position: 'relative', height: '500px' }}>
                    <Bar
                        options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Number of questions asked',
                                },
                            },
                            responsive: true,
                            scales: {
                                x: {
                                    stacked: true,
                                    ticks: {
                                        color: '#fff',
                                    },
                                },
                                y: {
                                    stacked: true,
                                    ticks: {
                                        color: '#fff',
                                    },
                                },
                            },
                        }}
                        data={{
                            labels: questions.map((question) =>
                                new Intl.DateTimeFormat('en-US').format(
                                    new Date(question.key)
                                )
                            ),
                            datasets: [
                                {
                                    label: 'Number of questions',
                                    data: questions.map(
                                        (question) => question.count
                                    ),
                                    borderWidth: 0,
                                    backgroundColor: '#b78fef',
                                },
                            ],
                        }}
                    />
                </div>
            </section>
        </div>
    );
}
