import React from 'react';
import { logout } from '../../firebaseConfig';
import { CiLogout } from 'react-icons/ci';
import { IconContext } from 'react-icons';
import { setDirectories } from '../../features/directories/directories_repository';

function Logout() {
    return (
        <IconContext.Provider
            value={{ color: '#fff', size: '1.5rem', title: 'Logout' }}
        >
            <span
                onClick={() => {
                    logout();
                    setDirectories({ list: [] });
                }}
                className="btn-icon"
            >
                <CiLogout aria-label="Logout" />
            </span>
        </IconContext.Provider>
    );
}
export default Logout;
