import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import { createDocumentFromCrawl } from '../../features/documents/documents_service';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft } from 'react-icons/ci';
import { updateIsLoading } from '../../features/global_loader/global_loader_service';

export function AddDocumentCrawl() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    let params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const [documentUrl, setDocumentUrl] = useState('');

    const handleCreateDocument = async (event) => {
        event.preventDefault();

        if (documentUrl) {
            updateIsLoading(true);
            await createDocumentFromCrawl(
                {
                    serviceId: activeService.service_id,
                    directoryId: params.directoryId,
                    url: documentUrl,
                },
                token
            );
            updateIsLoading(false);

            return navigate(`/directories/${params.directoryId}`);
        } else {
            setError('Document url is required');
        }
    };

    const handleChangeDocumentUrl = (event) => {
        setDocumentUrl(event.target.value);
        setError(null);
    };

    return (
        <section className="section">
            <section className={'breadcrumbs'}>
                <Link
                    className={'link breadcrumbs__link'}
                    to={`/directories/${params.directoryId}`}
                >
                    <IconContext.Provider
                        value={{
                            color: '#fff',
                            size: '1.5rem',
                            marginRight: '0.5rem',
                            title: 'Back to Directories',
                        }}
                    >
                        <CiCircleChevLeft aria-label="Back to Directories" />
                    </IconContext.Provider>
                    Back to: Directory
                </Link>
            </section>

            <header className="section__header">
                <h2 className="section__title">Get from website address</h2>
                <aside></aside>
            </header>

            <form className="add-document-form" onSubmit={handleCreateDocument}>
                <div className="form__control">
                    <label htmlFor="document-url">Website address (url)</label>
                    <input
                        className={error ? 'control-error' : ''}
                        id={'document-url'}
                        value={documentUrl}
                        onChange={handleChangeDocumentUrl}
                        type="text"
                    />
                    {error && <span className="error">{error}</span>}
                </div>

                <button className="btn" type="submit">
                    Crawl url
                </button>
            </form>
        </section>
    );
}
