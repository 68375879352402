import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from '../Login';
import Register from '../Register';
import Reset from '../Reset';
import ProtectedRoute from './protected_route';
import DirectoriesList from '../pages/directories/directories_list';
import { Directory } from '../pages/directories/directory';
import { AddDocument } from '../pages/documents/add_document';
import { EditDocument } from '../pages/documents/edit_document';
import { QuestionsRaport } from '../pages/questions/questions_raport';
import { QuestionsUsage } from '../pages/questions/questions_usage';
import { SlackConnect } from '../pages/slack/connect';
import { Integrations } from '../pages/integrations/integrations';
import { AddDocumentCrawl } from '../pages/documents/add_document_crawl';
import Wizard from '../pages/wizard/wizard';
import { RestApi } from '../pages/rest_api/rest_api';

function Routing() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/reset" element={<Reset />} />
                <Route
                    exact
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <div>
                                <DirectoriesList />
                                <div className={'hide-mobile'}>
                                    <QuestionsUsage />
                                </div>
                            </div>
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/slack/connect"
                    element={
                        <ProtectedRoute>
                            <SlackConnect />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/directories"
                    element={
                        <ProtectedRoute>
                            <DirectoriesList />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/integrations"
                    element={
                        <ProtectedRoute>
                            <Integrations />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/wizard"
                    element={
                        <ProtectedRoute>
                            <Wizard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/directories/:directoryId"
                    element={
                        <ProtectedRoute>
                            <Directory />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/directories/:directoryId/add"
                    element={
                        <ProtectedRoute>
                            <AddDocument />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/directories/:directoryId/addCrawl"
                    element={
                        <ProtectedRoute>
                            <AddDocumentCrawl />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/directories/:directoryId/document/:documentId"
                    element={
                        <ProtectedRoute>
                            <EditDocument />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/questions"
                    element={
                        <ProtectedRoute>
                            <QuestionsRaport />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/usage"
                    element={
                        <ProtectedRoute>
                            <QuestionsUsage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/rest-api"
                    element={
                        <ProtectedRoute>
                            <RestApi />
                        </ProtectedRoute>
                    }
                />
                >
            </Routes>
        </Router>
    );
}

export default Routing;
