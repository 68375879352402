import {createStore, select, withProps} from '@ngneat/elf';

export const AUTH_STORE_NAME = 'auth';

const userStore = createStore(
    { name: AUTH_STORE_NAME },
    withProps({ token: '' }),
);


export const token$ = userStore.pipe(select((state) => state.token));

export function updateToken(token) {
    userStore.update(() => ({
        token
    }));
}