import { http } from '../requester/requester';

export async function createService(token, name, selectedPlan) {
    try {
        await http({
            url: `https://api.gandy.ai/ui/services?name=${name}&plan=${selectedPlan}`,
            method: 'post',
            headers: {
                Authorization: token,
            },
        });
    } catch (error) {
        console.error(error);
    }
}
