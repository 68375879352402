import { http } from '../requester/requester';
import { logout } from '../../firebaseConfig';

export async function getSlackConnection(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/slack-connection`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response;
    } catch (err) {
        return err;
    }
}

export async function connectToSlack(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/slack-connection/${options.token}`,
            method: 'post',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response;
    } catch (err) {
        return err;
    }
}

export async function setDirectoryForSlack(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/slack-connection/default-directory/${options.directoryId}`,
            method: 'post',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response;
    } catch (err) {
        return err;
    }
}
