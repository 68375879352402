import React, { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    createToken,
    disableToken,
    enableToken,
    readTokens,
    removeToken,
} from '../../features/rest_api/rest_api_service';
import { restApiTokens$ } from '../../features/rest_api/rest_api_repository';
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/react';
import { IconContext } from 'react-icons';
import {
    CiCircleChevLeft,
    CiCirclePlus,
    CiCircleRemove,
    CiEdit,
    CiSettings,
    CiTrash,
} from 'react-icons/ci';
import { Link } from 'react-router-dom';

export function RestApi() {
    const [activeService] = useObservable(activeService$);
    const [token] = useObservable(token$);
    const [restApiTokens] = useObservable(restApiTokens$);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newTokenName, setNewTokenName] = useState('');
    const [restApiToken, setRestApiToken] = useState(null);

    const createNewToken = async (event) => {
        event.preventDefault();

        setIsModalOpen(false);

        const restApiTokenResponse = await createToken(
            {
                name: newTokenName,
                serviceId: activeService.service_id,
            },
            token
        );

        setRestApiToken(restApiTokenResponse.token);
        readTokens({ serviceId: activeService.service_id }, token);
    };

    const showCreateNewTokenModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                readTokens({ serviceId: activeService.service_id }, token);
            }
        }
        fetchData();
    }, [activeService, token]);

    return (
        <section className={'section'}>
            <section className={'breadcrumbs'}>
                <Link className={'link breadcrumbs__link'} to={'/integrations'}>
                    <IconContext.Provider
                        value={{
                            color: '#fff',
                            size: '1.5rem',
                            marginRight: '0.5rem',
                            title: 'Back to integrations',
                        }}
                    >
                        <CiCircleChevLeft aria-label="Back to integrations" />
                    </IconContext.Provider>
                    Back to: Integrations
                </Link>
            </section>

            <header className="section__header">
                <h2 className="section__title">Tokens</h2>
                <aside>
                    <a
                        onClick={async () => {
                            showCreateNewTokenModal();
                        }}
                        className={'btn-action'}
                    >
                        <IconContext.Provider
                            value={{
                                color: '#fff',
                                size: '1.5rem',
                                title: 'Create tokek',
                            }}
                        >
                            <CiCirclePlus aria-label="Create token" />
                        </IconContext.Provider>
                        Create token
                    </a>
                </aside>
            </header>

            {restApiToken && (
                <div className={'token-container'}>
                    <div className={'token-remind'}>
                        Make sure to copy your personal access token now. You
                        won’t be able to see it again!
                    </div>{' '}
                    <input
                        readOnly={true}
                        value={restApiToken}
                        class={'token'}
                    />
                </div>
            )}

            {restApiTokens && restApiTokens.length > 0 && (
                <table className="files-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className={'cell_action'}>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {restApiTokens.map((restApiToken) => (
                            <tr key={restApiToken.token_id}>
                                <td
                                    className={
                                        restApiToken.disabled
                                            ? 'token-disabled'
                                            : 'token-enabled'
                                    }
                                >
                                    {restApiToken.name}{' '}
                                    {restApiToken.disabled && '(disabled)'}
                                </td>
                                <td className={'cell_action'}>
                                    <Menu>
                                        <MenuButton>
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Actions',
                                                }}
                                            >
                                                <span className="btn-icon">
                                                    <CiSettings aria-label="Actions" />
                                                </span>
                                            </IconContext.Provider>
                                        </MenuButton>
                                        <MenuItems
                                            className="dropdown__content"
                                            anchor="bottom end"
                                        >
                                            <MenuItem>
                                                <>
                                                    {restApiToken.disabled ===
                                                        false && (
                                                        <span
                                                            onClick={async () => {
                                                                await disableToken(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                        tokenId:
                                                                            restApiToken.token_id,
                                                                        name: restApiToken.name,
                                                                    },
                                                                    token
                                                                );

                                                                readTokens(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                    },
                                                                    token
                                                                );
                                                            }}
                                                            className={
                                                                'dropdown__item'
                                                            }
                                                        >
                                                            <IconContext.Provider
                                                                value={{
                                                                    color: '#fff',
                                                                    size: '1.5rem',
                                                                    title: 'Disable',
                                                                }}
                                                            >
                                                                <CiEdit aria-label="Disable" />
                                                            </IconContext.Provider>
                                                            Disable
                                                        </span>
                                                    )}

                                                    {restApiToken.disabled ===
                                                        true && (
                                                        <span
                                                            onClick={async () => {
                                                                await enableToken(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                        tokenId:
                                                                            restApiToken.token_id,
                                                                        name: restApiToken.name,
                                                                    },
                                                                    token
                                                                );

                                                                readTokens(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                    },
                                                                    token
                                                                );
                                                            }}
                                                            className={
                                                                'dropdown__item'
                                                            }
                                                        >
                                                            <IconContext.Provider
                                                                value={{
                                                                    color: '#fff',
                                                                    size: '1.5rem',
                                                                    title: 'Enable',
                                                                }}
                                                            >
                                                                <CiEdit aria-label="Enable" />
                                                            </IconContext.Provider>
                                                            Enable
                                                        </span>
                                                    )}
                                                </>
                                            </MenuItem>

                                            <MenuItem>
                                                <>
                                                    <span
                                                        onClick={async () => {
                                                            await removeToken(
                                                                {
                                                                    serviceId:
                                                                        activeService.service_id,
                                                                    tokenId:
                                                                        restApiToken.token_id,
                                                                },
                                                                token
                                                            );
                                                            readTokens(
                                                                {
                                                                    serviceId:
                                                                        activeService.service_id,
                                                                },
                                                                token
                                                            );
                                                        }}
                                                        className={
                                                            'dropdown__item'
                                                        }
                                                    >
                                                        <IconContext.Provider
                                                            value={{
                                                                color: '#fff',
                                                                size: '1.5rem',
                                                                title: 'Enable',
                                                            }}
                                                        >
                                                            <CiTrash aria-label="Remove" />
                                                        </IconContext.Provider>
                                                        Remove
                                                    </span>
                                                </>
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className="relative z-50"
            >
                <div className="modal-wrapper">
                    <DialogPanel className="modal">
                        <DialogTitle className="font-bold">
                            Enter token name
                            <span
                                className={'btn-action'}
                                onClick={() => setIsModalOpen(false)}
                            >
                                <IconContext.Provider
                                    value={{
                                        color: '#fff',
                                        size: '2rem',
                                        title: 'Close',
                                    }}
                                >
                                    <CiCircleRemove aria-label="Close" />
                                </IconContext.Provider>
                            </span>
                        </DialogTitle>
                        <Description>This will create new token</Description>

                        <form
                            className="add-directory-form"
                            onSubmit={createNewToken}
                        >
                            <input
                                type={'text'}
                                onChange={(e) =>
                                    setNewTokenName(e.target.value)
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Escape') {
                                        setNewTokenName(e.target.value);
                                        createNewToken();
                                    }
                                }}
                            />
                            <button className="btn" type="submit">
                                Create new
                            </button>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
        </section>
    );
}
