import React, { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { userHasService$ } from '../../features/user/user_repository';
import { useNavigate } from 'react-router-dom';
import { token$ } from '../../features/auth/auth_repository';
import { createService } from '../../features/services/services_service';
import './wizard.css';
import { fetchUser } from '../../features/user/user_service';
import { CiBookmarkCheck, CiCirclePlus } from 'react-icons/ci';
import { IconContext } from 'react-icons';

const PLANS = [
    {
        id: 0,
        name: 'Trial',
        pricing: {
            eur: 0,
            usd: 0,
            pln: 0,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: ['20 questions per month', 'Up to 5 documents'],
    },
    {
        id: 2,
        name: 'Starter',
        pricing: {
            eur: 9,
            usd: 9,
            pln: 39,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: [
            '50 questions per month',
            'Up to 50 documents',
            'Slack integration',
        ],
    },
    {
        id: 3,
        name: 'Team',
        pricing: {
            eur: 49,
            usd: 49,
            pln: 199,
        },
        isFeatured: true,
        description: 'The essentials to provide your best work for clients.',
        features: [
            '500 questions per month',
            'Up to 1000 documents',
            'Slack integration',
        ],
    },
    {
        id: 4,
        name: 'Organization',
        pricing: {
            eur: 79,
            usd: 79,
            pln: 299,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: [
            'Unlimited questions',
            'Unlimited documents',
            'Slack integration',
        ],
    },
];

function Wizard() {
    const [token] = useObservable(token$);
    const [userHasServices] = useObservable(userHasService$);
    const navigate = useNavigate();
    const [serviceName, setServiceName] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(PLANS[2].id);
    const [step, setStep] = useState(1);

    const handleChange = (event) => {
        setServiceName(event.target.value);
    };

    const handleNextStep = (event) => {
        event.preventDefault();
        setStep(2);
    };

    const handleCreateService = async (event) => {
        event.preventDefault();
        await createService(token, serviceName, selectedPlan);
        await fetchUser(token);

        const slackToken = localStorage.getItem('slackToken');
        if (slackToken) {
            localStorage.removeItem('slackToken');
            return navigate(`/slack/connect?t=${slackToken}`);
        }

        return navigate('/admin');
    };

    useEffect(() => {
        if (userHasServices) {
            return navigate('/admin');
        }
    }, [navigate, userHasServices]);

    return (
        <article>
            <section className="section section_wizard">
                <header className="section__header">
                    <h2 className="section__title">Welcome to Gandy.ai!</h2>
                </header>

                <div className={'welcome-message'}>
                    <p>
                        We're delighted to have you on board. To kickstart your
                        journey, please create your workspace by giving it a
                        name and choose your plan. This will be your personal
                        area where you can manage knowledge, create directories,
                        upload documents, and more. If you need any help or have
                        questions, feel free to reach out to us. Let's get
                        started!
                    </p>

                    <p>Best wishes, The Gandy.ai Team</p>
                </div>

                {step === 1 && (
                    <form className="wizard-form" onSubmit={handleNextStep}>
                        <div className="wizard-form__control">
                            <label htmlFor="service_name">Workspace name</label>
                            <input
                                value={serviceName}
                                onChange={handleChange}
                                type="text"
                                placeholder="e.g. My workspace"
                            />
                        </div>

                        <button className="btn" type="submit">
                            Next
                        </button>
                    </form>
                )}

                {step === 2 && (
                    <div className={'pricing'}>
                        {PLANS.map((plan) => (
                            <div
                                className={`pricing__card ${plan.id === selectedPlan ? 'pricing__card_featured' : ''}`}
                            >
                                <h3>{plan.name}</h3>
                                <p>{plan.description}</p>

                                <p className={'price'}>
                                    <span className={'price__value'}>
                                        {navigator.language === 'pl'
                                            ? new Intl.NumberFormat('pl-PL', {
                                                  style: 'currency',
                                                  currency: 'PLN',
                                              }).format(plan.pricing.pln)
                                            : new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: 'USD',
                                              }).format(plan.pricing.usd)}
                                    </span>{' '}
                                    / month
                                </p>

                                <button
                                    className={`${plan.id !== selectedPlan ? 'btn_secondary' : ''} btn pricing-card__button`}
                                    type={'button'}
                                    onClick={(ev) => {
                                        setSelectedPlan(plan.id);
                                        handleCreateService(ev);
                                    }}
                                >
                                    Choose
                                </button>

                                <ul>
                                    {plan.features.map((feature) => (
                                        <li>
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Check',
                                                }}
                                            >
                                                <CiBookmarkCheck aria-label="Check" />
                                            </IconContext.Provider>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </section>
        </article>
    );
}

export default Wizard;
