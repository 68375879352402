import React from 'react';
import './account.css';
import { useObservable } from '@ngneat/react-rxjs';
import { user$, userInitials$ } from '../../features/user/user_repository';
import { CiMenuBurger } from 'react-icons/ci';
import { IconContext } from 'react-icons';

function Account() {
    const [user] = useObservable(user$);
    const [userInitials] = useObservable(userInitials$);

    return (
        <div className="account">
            <div
                onClick={() => {
                    document
                        .querySelector('.main-nav')
                        .classList.toggle('show');
                }}
                className={'hide-desktop'}
            >
                <IconContext.Provider
                    value={{
                        color: '#fff',
                        size: '1.5rem',
                        title: 'toggle menu',
                    }}
                >
                    <CiMenuBurger aria-label="toggle menu" />
                </IconContext.Provider>
            </div>

            <figure className="account__avatar">{userInitials}</figure>
            <p className="account__name">
                Hello, {user.name} <br />{' '}
                <small className="account__welcome-text">
                    Welcome to gandy.ai
                </small>
            </p>
        </div>
    );
}

export default Account;
