import { createStore, select, withProps } from '@ngneat/elf';

export const USAGE_STORE_NAME = 'usage';

const usageStore = createStore(
    { name: USAGE_STORE_NAME },
    withProps({
        name: '',
        questionsLimit: 0,
        documentsLimit: 0,
        isTrial: false,
        questionsUsage: 0,
        documentsUsage: 0,
    })
);

export function setUsage(usage) {
    usageStore.update((state) => ({
        ...state,
        name: usage.name,
        questionsLimit: usage.questions_limit,
        documentsLimit: usage.documents_limit,
        isTrial: usage.is_trial,
        questionsUsage: usage.questions_usage,
        documentsUsage: usage.documents_usage,
    }));
}

export const usage$ = usageStore.pipe(select((state) => state));
