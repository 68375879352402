import { http } from '../requester/requester';
import { updateAnswer } from './ask_questions_repository';
import { logout } from '../../firebaseConfig';

export async function askDocument(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/ask-document/${options.documentId}?question=${options.question}`,
            method: 'get',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        updateAnswer(response.data);

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function askDirectory(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/ask-directory/${options.directoryId}?question=${options.question}`,
            method: 'get',
            headers: {
                Authorization: token,
            },
        });

        updateAnswer(response.data);

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}
