import { http } from '../requester/requester';
import { logout } from '../../firebaseConfig';

export async function getQuestionsRaport(options, token) {
    try {
        const params = new URLSearchParams();

        for (const key in options.params) {
            params.append(key, options.params[key]);
        }

        let paramsString = '';
        if (params.size > 0) {
            paramsString = `?${params.toString()}`;
        }

        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/questions${paramsString}`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200 && response.data) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function getQuestionsStatsRaport(options, token) {
    try {
        const params = new URLSearchParams();

        for (const key in options.params) {
            params.append(key, options.params[key]);
        }

        let paramsString = '';
        if (params.size > 0) {
            paramsString = `?${params.toString()}`;
        }

        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/questions-stats${paramsString}`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200 && response.data) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}
