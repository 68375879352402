import React, { useEffect, useState } from 'react';
import {
    connectToSlack,
    getSlackConnection,
    setDirectoryForSlack,
} from '../../features/slack/slack_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { useSearchParams } from 'react-router-dom';
import { directories$ } from '../../features/directories/directories_repository';
import { getDirectories } from '../../features/directories/directories_service';

export function SlackConnect() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [searchParams] = useSearchParams();
    const [isConnected, setIsConnected] = useState(false);
    const [slackConnectionInfo, setSlackConnectionInfo] = useState(null);
    const [directories] = useObservable(directories$);

    useEffect(() => {
        if (activeService) {
            const fetchData = async () => {
                if (directories.length <= 0) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            };

            fetchData();
        }
    }, [activeService, directories.length, token]);

    useEffect(() => {
        if (activeService) {
            const fetchData = async () => {
                const slackConnectionStatus = await getSlackConnection(
                    { serviceId: activeService.service_id },
                    token
                );

                if (slackConnectionStatus.response?.status === 404) {
                    setIsConnected(false);
                    await connectToSlack(
                        {
                            serviceId: activeService.service_id,
                            token: searchParams.get('t'),
                        },
                        token
                    );

                    if (directories) {
                        await setDirectoryForSlack(
                            {
                                serviceId: activeService.service_id,
                                directoryId: directories[0].id,
                            },
                            token
                        );
                    }
                }

                if (slackConnectionStatus.status === 200) {
                    setIsConnected(true);
                    setSlackConnectionInfo(slackConnectionStatus.data);
                }
            };

            fetchData();
        }
    }, [searchParams, activeService, token, directories]);

    const setDefaultDirectory = (directoryId) => {
        setDirectoryForSlack(
            {
                serviceId: activeService.service_id,
                directoryId,
            },
            token
        );
    };

    return (
        <article>
            <section className="section">
                {isConnected && (
                    <>
                        <header className="section__header">
                            <h2 className="section__title">
                                Connected to slack
                            </h2>
                        </header>
                        <p>
                            You are connected to:{' '}
                            <strong>{slackConnectionInfo.name}</strong>
                            <br />
                            Slack URL: {slackConnectionInfo.slack_url}
                        </p>

                        {directories && (
                            <div
                                className={'form__control form__control_inline'}
                            >
                                <label htmlFor={'aggregate'}>
                                    Gandy AI knowledge directory:
                                    <select
                                        className={'control'}
                                        onChange={(ev) => {
                                            setDefaultDirectory(
                                                ev.target.value
                                            );
                                        }}
                                        defaultValue={
                                            slackConnectionInfo.default_directory
                                        }
                                        id={'aggregate'}
                                    >
                                        {directories.map((directory) => (
                                            <option
                                                key={directory.id}
                                                value={directory.id}
                                            >
                                                {directory.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                        )}
                    </>
                )}
                {!isConnected && (
                    <>
                        <header className="section__header">
                            <h2 className="section__title">
                                Connecting to slack
                            </h2>
                        </header>

                        <button className={'btn'}>Connect to slack</button>
                    </>
                )}
            </section>
        </article>
    );
}
