import { http } from '../requester/requester';
import { logout } from '../../firebaseConfig';
import { setUsage } from './usage_repsitory';

export async function getUsage(options, token) {
    const response = await http({
        url: `https://api.gandy.ai/ui/${options.serviceId}/usage`,
        headers: {
            Authorization: token,
        },
    });

    if (response.status === 401) {
        logout();
        return;
    }

    setUsage(response.data);
}
