import React from 'react';
import './top_nav.css';
import Logout from '../logout/logout';
import Account from '../account/account';
import Usage from '../usage/usage';

function TopNav() {
    return (
        <nav className="top-nav">
            <Account />

            <aside>
                <Usage />
                <Logout />
            </aside>
        </nav>
    );
}

export default TopNav;
