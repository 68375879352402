import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import { createDocument } from '../../features/documents/documents_service';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft } from 'react-icons/ci';
import { updateIsLoading } from '../../features/global_loader/global_loader_service';

export function AddDocument() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    let params = useParams();
    const navigate = useNavigate();

    const [documentName, setDocumentName] = useState('');
    const [documentUrl, setDocumentUrl] = useState('');
    const [documentContent, setDocumentContent] = useState('');

    const [documentNameError, setDocumentNameError] = useState(null);
    const [documentContentError, setDocumentContentError] = useState(null);

    const handleCreateDocument = async (event) => {
        event.preventDefault();

        if (documentName && documentContent) {
            updateIsLoading(true);
            await createDocument(
                {
                    serviceId: activeService.service_id,
                    directoryId: params.directoryId,
                    name: documentName,
                    url: documentUrl,
                    content: documentContent,
                },
                token
            );

            updateIsLoading(false);
            return navigate(`/directories/${params.directoryId}`);
        } else {
            setDocumentNameError('Document name is required');
            setDocumentContentError('Document content is required');
        }
    };

    const handleChangeDocumentName = (event) => {
        setDocumentName(event.target.value);
        setDocumentNameError(null);
    };

    const handleChangeDocumentUrl = (event) => {
        setDocumentUrl(event.target.value);
    };

    const handleChangeDocumentContent = (event) => {
        setDocumentContent(event.target.value);
        setDocumentContentError(null);
    };

    useEffect(() => {}, []);

    return (
        <section className="section">
            <section className={'breadcrumbs'}>
                <Link
                    className={'link breadcrumbs__link'}
                    to={`/directories/${params.directoryId}`}
                >
                    <IconContext.Provider
                        value={{
                            color: '#fff',
                            size: '1.5rem',
                            marginRight: '0.5rem',
                            title: 'Back to Directories',
                        }}
                    >
                        <CiCircleChevLeft aria-label="Back to Directories" />
                    </IconContext.Provider>
                    Back to: Directory
                </Link>
            </section>

            <header className="section__header">
                <h2 className="section__title">Add document</h2>
                <aside></aside>
            </header>

            <form className="add-document-form" onSubmit={handleCreateDocument}>
                <div className="form__control">
                    <label htmlFor="document-name">Document name</label>
                    <input
                        id={'document-name'}
                        value={documentName}
                        onChange={handleChangeDocumentName}
                        type="text"
                        className={documentNameError ? 'control-error' : ''}
                    />
                    {documentNameError && (
                        <span className="error">{documentNameError}</span>
                    )}
                </div>
                <div className="form__control">
                    <label htmlFor="document-url">Document url</label>
                    <input
                        id={'document-url'}
                        value={documentUrl}
                        onChange={handleChangeDocumentUrl}
                        type="text"
                    />
                </div>

                <div className="form__control">
                    <label htmlFor="document-content">Document content</label>
                    <textarea
                        id={'document-content'}
                        value={documentContent}
                        className={documentContentError ? 'control-error' : ''}
                        onChange={handleChangeDocumentContent}
                    />
                    {documentContentError && (
                        <span className="error">{documentContentError}</span>
                    )}
                </div>

                <button className="btn" type="submit">
                    Create document
                </button>
            </form>
        </section>
    );
}
