import { createStore, select, withProps } from '@ngneat/elf';

export const ASK_QUESTIONS_STORE_NAME = 'ask_questions_store';

const askQuestionsStore = createStore(
    { name: ASK_QUESTIONS_STORE_NAME },
    withProps({ answer: null })
);

export const answer$ = askQuestionsStore.pipe(select((answer) => answer));

export function updateAnswer(answer) {
    askQuestionsStore.update((state) => answer);
}
