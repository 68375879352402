import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react';
import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import { CiCircleRemove } from 'react-icons/ci';

export function Confirm({
    isModalOpen,
    title,
    content,
    onOk,
    okText,
    cancelText,
    onClose,
}) {
    return (
        <Dialog
            open={isModalOpen}
            onClose={() => onClose()}
            className="relative z-50"
        >
            <div className="modal-wrapper">
                <DialogPanel className="modal">
                    <DialogTitle className="font-bold">
                        {title}{' '}
                        <span
                            className={'btn-action'}
                            onClick={() => onClose()}
                        >
                            <IconContext.Provider
                                value={{
                                    color: '#fff',
                                    size: '2rem',
                                    title: 'Close',
                                }}
                            >
                                <CiCircleRemove aria-label="Close" />
                            </IconContext.Provider>
                        </span>
                    </DialogTitle>

                    <Description className={'confirm__description'}>
                        {content}
                    </Description>

                    <div className="confirm__buttons">
                        <button
                            class={'btn btn_secondary'}
                            onClick={() => onClose()}
                        >
                            {cancelText}
                        </button>
                        <button class={'btn'} onClick={() => onOk()}>
                            {okText}
                        </button>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
}
