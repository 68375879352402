import { createStore, select, withProps } from '@ngneat/elf';

export const USER_STORE_NAME = 'user';
export const EMPTY_USER = {
    email: '',
    name: '',
    isVerified: false,
    services: [],
};

const userStore = createStore({ name: USER_STORE_NAME }, withProps(EMPTY_USER));

export const user$ = userStore.pipe(select((user) => user));
export const userInitials$ = userStore.pipe(
    select((user) =>
        user.name
            .split(' ')
            .map((n) => n[0])
            .join('')
    )
);
export const userHasService$ = userStore.pipe(
    select((user) => user.services.length > 0)
);
export const activeService$ = userStore.pipe(
    select((user) => user.activeService)
);

export function updateUser(user) {
    const activeIndex = localStorage.getItem('activeService') || 0;
    const service = user.services[activeIndex];
    const activeService = service || user.services[0];

    userStore.update((state) => ({
        ...state,
        email: user.email,
        name: user.name,
        isVerified: user.verified,
        services: user.services,
        activeService: activeService,
    }));
}

export function updateActiveService(service) {
    userStore.update((state) => ({
        ...state,
        activeService: service,
    }));
}
