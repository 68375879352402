import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    createDocument,
    readDocument,
    removeDocument,
    updateDocument,
} from '../../features/documents/documents_service';
import {
    activeDocument$,
    isActiveDocumentLoading$,
    setIsLoading,
} from '../../features/documents/documents_repository';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react';
import {
    answer$,
    updateAnswer,
} from '../../features/ask_questions/ask_questions_repository';
import { askDocument } from '../../features/ask_questions/ask_questions_service';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft, CiCircleRemove, CiTrash } from 'react-icons/ci';
import { Confirm } from '../../components/confirm/confirm';

export function EditDocument() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [activeDocument] = useObservable(activeDocument$);
    let params = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer] = useObservable(answer$);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isActiveDocumentLoading] = useObservable(isActiveDocumentLoading$);

    const [documentName, setDocumentName] = useState(
        activeDocument?.name || ''
    );
    const [documentUrl, setDocumentUrl] = useState(activeDocument?.url || '');
    const [documentContent, setDocumentContent] = useState(
        activeDocument?.content || ''
    );

    const handleAskDocument = async () => {
        setIsModalOpen(true);
        updateAnswer({ answer: null, sources: [] });

        await askDocument(
            {
                serviceId: activeService.service_id,
                documentId: params.documentId,
                question,
            },
            token
        );
    };

    const handleUpdateDocument = async (event) => {
        event.preventDefault();

        await updateDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId: activeDocument.id,
                name: documentName,
                url: documentUrl,
                content: documentContent,
                mimetype: activeDocument.mimetype,
            },
            token
        );

        return navigate(`/directories/${params.directoryId}`);
    };

    const handleChangeDocumentName = (event) => {
        setDocumentName(event.target.value);
    };

    const handleChangeDocumentUrl = (event) => {
        setDocumentUrl(event.target.value);
    };

    const handleChangeDocumentContent = (event) => {
        setDocumentContent(event.target.value);
    };

    const handleRemoveDocument = async (documentId) => {
        await removeDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId,
            },
            token
        );

        return navigate(`/directories/${params.directoryId}`);
    };

    useEffect(() => {
        if (activeService) {
            async function fetchData() {
                await readDocument(
                    {
                        serviceId: activeService.service_id,
                        directoryId: params.directoryId,
                        documentId: params.documentId,
                    },
                    token
                );
            }

            fetchData();
        }
    }, [activeService, params.directoryId, params.documentId, token]);

    useEffect(() => {
        setDocumentName(activeDocument?.name || '');
        setDocumentUrl(activeDocument?.url || '');
        setDocumentContent(activeDocument?.content || '');
    }, [activeDocument]);

    return (
        <section className="section">
            <section className={'breadcrumbs'}>
                <Link
                    className={'link breadcrumbs__link'}
                    to={`/directories/${params.directoryId}`}
                >
                    <IconContext.Provider
                        value={{
                            color: '#fff',
                            size: '1.5rem',
                            marginRight: '0.5rem',
                            title: 'Back to Directories',
                        }}
                    >
                        <CiCircleChevLeft aria-label="Back to Directories" />
                    </IconContext.Provider>
                    Back to: Directory
                </Link>
            </section>

            {isActiveDocumentLoading ? (
                <div className={'loader'}></div>
            ) : (
                <>
                    <header className="section__header">
                        <h2 className="section__title">Edit document</h2>
                        <aside>
                            <a
                                onClick={() => {
                                    setIsConfirmOpen(true);
                                }}
                                className={'btn-action'}
                            >
                                <IconContext.Provider
                                    value={{
                                        color: '#fff',
                                        size: '1.5rem',
                                        title: 'Remove',
                                    }}
                                >
                                    <CiTrash aria-label="Remove" />
                                </IconContext.Provider>
                                Remove
                            </a>

                            <Confirm
                                isModalOpen={isConfirmOpen}
                                title={`Removing document: ${documentName}`}
                                content={
                                    'Are you sure you want to remove this document?'
                                }
                                okText={'Remove'}
                                cancelText={'Cancel'}
                                onOk={() => {
                                    handleRemoveDocument(params.documentId);
                                    setIsConfirmOpen(false);
                                }}
                                onClose={() => {
                                    setIsConfirmOpen(false);
                                }}
                            />
                        </aside>
                    </header>

                    <form
                        className="add-document-form"
                        onSubmit={handleUpdateDocument}
                    >
                        {activeDocument && (
                            <>
                                <div className="form__control">
                                    <label htmlFor="document-name">
                                        Document name
                                    </label>
                                    <input
                                        id={'document-name'}
                                        value={documentName}
                                        onChange={handleChangeDocumentName}
                                        type="text"
                                        disabled={
                                            activeDocument.mimetype ===
                                            'web/crawled'
                                        }
                                    />
                                </div>
                                <div className="form__control">
                                    <label htmlFor="document-url">
                                        Document url
                                    </label>
                                    <input
                                        id={'document-url'}
                                        value={documentUrl}
                                        onChange={handleChangeDocumentUrl}
                                        type="text"
                                        disabled={
                                            activeDocument.mimetype ===
                                            'web/crawled'
                                        }
                                    />
                                </div>

                                {activeDocument.mimetype === 'text/plain' && (
                                    <div className="form__control">
                                        <label htmlFor="document-content">
                                            Document content
                                        </label>
                                        <textarea
                                            id={'document-content'}
                                            value={documentContent}
                                            onChange={
                                                handleChangeDocumentContent
                                            }
                                            disabled={
                                                activeDocument.mimetype ===
                                                'web/crawled'
                                            }
                                        />
                                    </div>
                                )}

                                {activeDocument.mimetype !== 'web/crawled' && (
                                    <button className="btn" type="submit">
                                        Save document
                                    </button>
                                )}
                            </>
                        )}
                    </form>

                    <br />
                    <br />

                    <div className="question-box">
                        <input
                            onChange={(ev) => {
                                setQuestion(ev.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.code === 'Enter') {
                                    setQuestion(ev.target.value);
                                    handleAskDocument();
                                }
                            }}
                            className="question-box__input"
                            type="text"
                        />
                        <button
                            onClick={() => handleAskDocument()}
                            className="btn question-box__btn"
                        >
                            Ask question
                        </button>
                    </div>

                    <Dialog
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        className="relative z-50"
                    >
                        <div className="modal-wrapper">
                            <DialogPanel className="modal">
                                <DialogTitle className="font-bold">
                                    {question}

                                    <span
                                        className={'btn-action'}
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        <IconContext.Provider
                                            value={{
                                                color: '#fff',
                                                size: '2rem',
                                                title: 'Close',
                                            }}
                                        >
                                            <CiCircleRemove aria-label="Close" />
                                        </IconContext.Provider>
                                    </span>
                                </DialogTitle>

                                {answer.answer && (
                                    <>
                                        <div
                                            className={'asnwer'}
                                            dangerouslySetInnerHTML={{
                                                // eslint-disable-next-line no-undef
                                                __html: marked.parse(
                                                    answer.answer
                                                ),
                                            }}
                                        ></div>
                                        <br />
                                        <h3>Source context</h3>
                                        <br />
                                        <div>
                                            {answer.sources.map((source) => (
                                                <Disclosure
                                                    className={'disclosure'}
                                                    as={'div'}
                                                >
                                                    <DisclosureButton className="py-2">
                                                        {source.name}, page:{' '}
                                                        {source.page}
                                                    </DisclosureButton>
                                                    <DisclosurePanel className="text-gray-500">
                                                        {source.content} - test
                                                    </DisclosurePanel>
                                                </Disclosure>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {!answer.answer && (
                                    <div className={'question-loader'}>
                                        <div className={'loader'}></div>
                                    </div>
                                )}
                            </DialogPanel>
                        </div>
                    </Dialog>
                </>
            )}
        </section>
    );
}
