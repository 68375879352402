// src/components/sliding_text/sliding_text.js
import React, { useState, useEffect } from 'react';
import './sliding_text.css';

const SlidingText = ({ texts, interval = 2000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (currentIndex >= texts.length - 1) {
            setTimeout(() => setFinished(true), interval);
            return;
        }

        const timer = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
                setFade(true);
            }, 1000); // Duration of fade-out animation
        }, interval);

        return () => clearInterval(timer);
    }, [texts, interval, currentIndex]);

    if (finished) {
        return null;
    }

    return (
        <div className={`sliding-text ${fade ? 'fade-in' : 'fade-out'}`}>
            {texts[currentIndex]}
        </div>
    );
};

export default SlidingText;
