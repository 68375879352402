import React, { useEffect } from 'react';
import { getUsage } from '../../features/usage/usage_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { usage$ } from '../../features/usage/usage_repsitory';

function Usage() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [usage] = useObservable(usage$);

    useEffect(() => {
        if (activeService) {
            getUsage(
                {
                    serviceId: activeService.service_id,
                },
                token
            );
        }
    }, [activeService, token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(navigator.language).format(date);
    };

    const usageStart = activeService?.plan?.usage_start;
    const usageEnd = activeService?.plan?.usage_end;

    return (
        <>
            <div>
                <div className={'usage'}>
                    <div className={'usage__question-limit'}>
                        <p>
                            Questions usage: {usage.questionsUsage} /{' '}
                            {usage.questionsLimit}
                        </p>
                        <div className={'bar'}>
                            <div
                                style={{
                                    width: `${((usage.questionsUsage * 100) / usage.questionsLimit / 100) * 200}px`,
                                }}
                                className={'bar__progress'}
                            ></div>
                        </div>
                    </div>
                    <div className={'usage__documents-limit'}>
                        <p>
                            Documents usage: {usage.documentsUsage} /{' '}
                            {usage.documentsLimit}
                        </p>
                        <div className={'bar'}>
                            <div
                                style={{
                                    width: `${((usage.documentsUsage * 100) / usage.documentsLimit / 100) * 200}px`,
                                }}
                                className={'bar__progress'}
                            ></div>
                        </div>
                    </div>
                </div>
                {usageStart && usageEnd && (
                    <div className={'usage__period'}>
                        <p>
                            Usage period: {formatDate(usageStart)} -{' '}
                            {formatDate(usageEnd)}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
}

export default Usage;
