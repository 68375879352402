import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    getDirectories,
    removeDirectory,
} from '../../features/directories/directories_service';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    getDocuments,
    removeDocument,
    uploadDocument,
} from '../../features/documents/documents_service';
import {
    currentPage$,
    documents$,
    pages$,
    setCurrentPage,
} from '../../features/documents/documents_repository';
import {
    CiCircleChevLeft,
    CiCircleChevRight,
    CiCirclePlus,
    CiCircleRemove,
    CiEdit,
    CiSettings,
    CiTrash,
} from 'react-icons/ci';
import { IconContext } from 'react-icons';
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/react';
import {
    getDirectory,
    hasDirectories,
} from '../../features/directories/directories_repository';
import { askDirectory } from '../../features/ask_questions/ask_questions_service';
import {
    answer$,
    updateAnswer,
} from '../../features/ask_questions/ask_questions_repository';
import { FileUploader } from 'react-drag-drop-files';
import { Confirm } from '../../components/confirm/confirm';
import { updateIsLoading } from '../../features/global_loader/global_loader_service';

export function Directory() {
    const [documents] = useObservable(documents$);
    const [token] = useObservable(token$);
    const [answer] = useObservable(answer$);
    const [pages] = useObservable(pages$);
    let params = useParams();
    const [activeService] = useObservable(activeService$);
    const navigate = useNavigate();
    const [directory] = useObservable(getDirectory(params.directoryId));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [limit, setLimit] = useState(10);

    const [page] = useObservable(currentPage$);

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);

    const handleRemoveDocument = async (documentId) => {
        updateIsLoading(true);
        await removeDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId,
            },
            token
        );

        getDocuments(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                params: {
                    limit,
                    page,
                },
            },
            token
        );
        updateIsLoading(false);
    };

    const handleChangePage = async (page) => {
        setCurrentPage(page);
    };

    const handleAskDirectory = async () => {
        setIsModalOpen(true);
        updateAnswer({ answer: null, sources: [] });

        const isSuccess = await askDirectory(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                question,
            },
            token
        );
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                if (!hasDirectories) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        }
        fetchData();
    }, [params, activeService, token]);

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                getDocuments(
                    {
                        serviceId: activeService.service_id,
                        directoryId: params.directoryId,
                        params: {
                            limit,
                            page,
                        },
                    },
                    token
                );
            }
        }
        fetchData();
    }, [params, activeService, token, limit, page]);

    const fileTypes = ['txt', 'pdf', 'md', 'html'];

    const handleChange = async (file) => {
        setIsInProgress(true);

        await uploadDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                data: file,
            },
            token
        );

        setIsInProgress(false);

        getDocuments(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                params: {
                    limit,
                    page,
                },
            },
            token
        );
    };

    const handleRemoveDirectory = async () => {
        updateIsLoading(true);

        await removeDirectory(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
            },
            token
        );

        updateIsLoading(false);

        return navigate('/directories');
    };

    return (
        <section className="section">
            <section className={'breadcrumbs'}>
                <Link className={'link breadcrumbs__link'} to={'/directories'}>
                    <IconContext.Provider
                        value={{
                            color: '#fff',
                            size: '1.5rem',
                            marginRight: '0.5rem',
                            title: 'Back to Directories',
                        }}
                    >
                        <CiCircleChevLeft aria-label="Back to Directories" />
                    </IconContext.Provider>
                    Back to: Directories
                </Link>
            </section>

            <header className="section__header">
                <h2 className="section__title">
                    {directory && directory.name}
                </h2>
                <aside>
                    <Link
                        className={'btn-action'}
                        to={`/directories/${params.directoryId}/addCrawl`}
                    >
                        <IconContext.Provider
                            value={{
                                color: '#fff',
                                size: '1.5rem',
                                title: 'Get from url',
                            }}
                        >
                            <CiCirclePlus aria-label="Get from url" />
                        </IconContext.Provider>
                        Get from url
                    </Link>

                    <Link
                        className={'btn-action'}
                        to={`/directories/${params.directoryId}/add`}
                    >
                        <IconContext.Provider
                            value={{
                                color: '#fff',
                                size: '1.5rem',
                                title: 'Add document',
                            }}
                        >
                            <CiCirclePlus aria-label="Add document" />
                        </IconContext.Provider>
                        Add document
                    </Link>

                    <a
                        onClick={async () => {
                            setIsConfirmOpen(true);
                        }}
                        className={'btn-action'}
                    >
                        <IconContext.Provider
                            value={{
                                color: '#fff',
                                size: '1.5rem',
                                title: 'Remove',
                            }}
                        >
                            <CiTrash aria-label="Remove" />
                        </IconContext.Provider>
                        Remove directory
                    </a>

                    {directory && (
                        <Confirm
                            isModalOpen={isConfirmOpen}
                            title={`Removing directory: ${directory.name}`}
                            content={
                                'Are you sure you want remove this directory?'
                            }
                            okText={'Remove'}
                            cancelText={'Cancel'}
                            onOk={() => {
                                handleRemoveDirectory();
                                setIsConfirmOpen(false);
                            }}
                            onClose={() => {
                                setIsConfirmOpen(false);
                            }}
                        />
                    )}
                </aside>
            </header>

            {!isInProgress && (
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                >
                    <form className="upload-area">
                        <button className="btn">Add files</button>
                        <p>Or drag stuff here</p>
                    </form>
                </FileUploader>
            )}

            {isInProgress && (
                <div className={'upload-area'}>
                    <div className={'loader'}></div>
                </div>
            )}

            {directory && documents.length > 0 ? (
                <>
                    <table className="files-table">
                        <thead>
                            <tr>
                                <td colSpan="99">
                                    <form
                                        className={
                                            'form pagination-form hide-mobile'
                                        }
                                    >
                                        <div
                                            className={
                                                'form__control form__control_inline'
                                            }
                                        >
                                            <label htmlFor={'limit'}>
                                                Show:
                                                <select
                                                    className={'control'}
                                                    id={'limit'}
                                                    onChange={(ev) => {
                                                        setLimit(
                                                            ev.target.value
                                                        );

                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <option value={10}>
                                                        10
                                                    </option>
                                                    <option value={20}>
                                                        20
                                                    </option>
                                                    <option value={50}>
                                                        50
                                                    </option>
                                                </select>
                                            </label>
                                        </div>

                                        <div className={'pagination__changer'}>
                                            {page > 1 && (
                                                <div
                                                    onClick={() => {
                                                        handleChangePage(
                                                            page - 1
                                                        );
                                                    }}
                                                >
                                                    <IconContext.Provider
                                                        value={{
                                                            color: '#fff',
                                                            size: '1.5rem',
                                                            title: 'Actions',
                                                        }}
                                                    >
                                                        <span className="btn-icon">
                                                            <CiCircleChevLeft aria-label="Previous page" />
                                                        </span>
                                                    </IconContext.Provider>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    'form__control form__control_inline'
                                                }
                                            >
                                                <label htmlFor={'limit'}>
                                                    Page:
                                                    <input
                                                        className={'control'}
                                                        type={'number'}
                                                        value={page}
                                                        max={pages}
                                                        min={1}
                                                        onChange={(ev) =>
                                                            setCurrentPage(
                                                                ev.target.value
                                                            )
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            of {pages}
                                            {page < pages && (
                                                <div
                                                    onClick={() => {
                                                        handleChangePage(
                                                            page + 1
                                                        );
                                                    }}
                                                >
                                                    <IconContext.Provider
                                                        value={{
                                                            color: '#fff',
                                                            size: '1.5rem',
                                                            title: 'Actions',
                                                        }}
                                                    >
                                                        <span className="btn-icon">
                                                            <CiCircleChevRight aria-label="Next page" />
                                                        </span>
                                                    </IconContext.Provider>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>Documents name</th>
                                <th className={'hide-mobile'}>Create date</th>
                                <th className={'hide-mobile'}>Link</th>
                                <th className={'cell_action'}>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {directory &&
                                documents.map((document) => (
                                    <tr key={document.id}>
                                        <td>
                                            <Link
                                                className={'link'}
                                                to={`/directories/${directory.id}/document/${document.id}`}
                                            >
                                                {document.name ? (
                                                    document.name
                                                ) : (
                                                    <div
                                                        className={'loader'}
                                                    ></div>
                                                )}
                                            </Link>
                                        </td>
                                        <td className={'hide-mobile'}>
                                            {new Intl.DateTimeFormat(
                                                'en-US'
                                            ).format(
                                                new Date(document.createDate)
                                            )}
                                        </td>
                                        <td className={'hide-mobile'}>
                                            {document.url}
                                        </td>
                                        <td className={'cell_action'}>
                                            <Menu>
                                                <MenuButton>
                                                    <IconContext.Provider
                                                        value={{
                                                            color: '#fff',
                                                            size: '1.5rem',
                                                            title: 'Actions',
                                                        }}
                                                    >
                                                        <span className="btn-icon">
                                                            <CiSettings aria-label="Actions" />
                                                        </span>
                                                    </IconContext.Provider>
                                                </MenuButton>
                                                <MenuItems
                                                    className="dropdown__content"
                                                    anchor="bottom end"
                                                >
                                                    <MenuItem>
                                                        <Link
                                                            className={
                                                                'dropdown__item'
                                                            }
                                                            to={`/directories/${directory.id}/document/${document.id}`}
                                                        >
                                                            <IconContext.Provider
                                                                value={{
                                                                    color: '#fff',
                                                                    size: '1.5rem',
                                                                    title: 'Edit',
                                                                }}
                                                            >
                                                                <CiEdit aria-label="Edit" />
                                                            </IconContext.Provider>
                                                            Edit
                                                        </Link>
                                                    </MenuItem>
                                                    <MenuItem>
                                                        <>
                                                            <span
                                                                className="dropdown__item"
                                                                onClick={() => {
                                                                    handleRemoveDocument(
                                                                        document.id
                                                                    );
                                                                }}
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: '#fff',
                                                                        size: '1.5rem',
                                                                        title: 'Remove',
                                                                    }}
                                                                >
                                                                    <CiTrash aria-label="Remove" />
                                                                </IconContext.Provider>
                                                                Remove
                                                            </span>
                                                        </>
                                                    </MenuItem>
                                                </MenuItems>
                                            </Menu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>

                        <tfoot>
                            <tr>
                                <td colSpan="99">
                                    <form className={'form pagination-form'}>
                                        <div
                                            className={
                                                'form__control form__control_inline'
                                            }
                                        >
                                            <label htmlFor={'limit'}>
                                                Show:
                                                <select
                                                    className={'control'}
                                                    id={'limit'}
                                                    onChange={(ev) => {
                                                        setLimit(
                                                            ev.target.value
                                                        );

                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <option value={10}>
                                                        10
                                                    </option>
                                                    <option value={20}>
                                                        20
                                                    </option>
                                                    <option value={50}>
                                                        50
                                                    </option>
                                                </select>
                                            </label>
                                        </div>

                                        <div className={'pagination__changer'}>
                                            {page > 1 && (
                                                <div
                                                    onClick={() => {
                                                        handleChangePage(
                                                            page - 1
                                                        );
                                                    }}
                                                >
                                                    <IconContext.Provider
                                                        value={{
                                                            color: '#fff',
                                                            size: '1.5rem',
                                                            title: 'Actions',
                                                        }}
                                                    >
                                                        <span className="btn-icon">
                                                            <CiCircleChevLeft aria-label="Previous page" />
                                                        </span>
                                                    </IconContext.Provider>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    'form__control form__control_inline'
                                                }
                                            >
                                                <label htmlFor={'limit'}>
                                                    Page:
                                                    <input
                                                        className={'control'}
                                                        type={'number'}
                                                        value={page}
                                                        max={pages}
                                                        min={1}
                                                        onChange={(ev) =>
                                                            setCurrentPage(
                                                                ev.target.value
                                                            )
                                                        }
                                                    />
                                                </label>
                                            </div>
                                            of {pages}
                                            {page < pages && (
                                                <div
                                                    onClick={() => {
                                                        handleChangePage(
                                                            page + 1
                                                        );
                                                    }}
                                                >
                                                    <IconContext.Provider
                                                        value={{
                                                            color: '#fff',
                                                            size: '1.5rem',
                                                            title: 'Actions',
                                                        }}
                                                    >
                                                        <span className="btn-icon">
                                                            <CiCircleChevRight aria-label="Next page" />
                                                        </span>
                                                    </IconContext.Provider>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <div className="question-box">
                        <input
                            onChange={(ev) => {
                                setQuestion(ev.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.code === 'Enter') {
                                    setQuestion(ev.target.value);
                                    handleAskDirectory(directory.id);
                                }
                            }}
                            className="question-box__input"
                            type="text"
                        />
                        <button
                            onClick={() => handleAskDirectory(directory.id)}
                            className="btn question-box__btn"
                        >
                            Ask question
                        </button>
                    </div>

                    <Dialog
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        className="relative z-50"
                    >
                        <div className="modal-wrapper">
                            <DialogPanel className="modal">
                                <DialogTitle className="font-bold">
                                    {question}

                                    <span
                                        className={'btn-action'}
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        <IconContext.Provider
                                            value={{
                                                color: '#fff',
                                                size: '2rem',
                                                title: 'Close',
                                            }}
                                        >
                                            <CiCircleRemove aria-label="Close" />
                                        </IconContext.Provider>
                                    </span>
                                </DialogTitle>

                                {answer.answer && (
                                    <>
                                        <div
                                            className={'asnwer'}
                                            dangerouslySetInnerHTML={{
                                                // eslint-disable-next-line no-undef
                                                __html: marked.parse(
                                                    answer.answer
                                                ),
                                            }}
                                        ></div>
                                        <br />
                                        <h3>Source context</h3>
                                        <br />
                                        <div>
                                            {answer.sources.map((source) => (
                                                <Disclosure
                                                    key={source.id}
                                                    className={'disclosure'}
                                                    as={'div'}
                                                >
                                                    <DisclosureButton className="py-2">
                                                        {source.name}
                                                        {source.page > 0 && (
                                                            <>
                                                                , page:{' '}
                                                                {source.page}
                                                            </>
                                                        )}
                                                    </DisclosureButton>
                                                    <DisclosurePanel className="text-gray-500">
                                                        {source.content}
                                                    </DisclosurePanel>
                                                </Disclosure>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {!answer.answer && (
                                    <div className={'question-loader'}>
                                        <div className={'loader'}></div>
                                    </div>
                                )}
                            </DialogPanel>
                        </div>
                    </Dialog>
                </>
            ) : (
                <div className={'empty-list'}>
                    <h3>You don't have any documents yet!</h3>
                    <p>
                        Upload your documents by dragging them into the field
                        above,{' '}
                        <Link
                            className={'link link_secondary'}
                            to={`/directories/${params.directoryId}/addCrawl`}
                        >
                            crawl your sites
                        </Link>
                        , or simply{' '}
                        <Link
                            className={'link link_secondary'}
                            to={`/directories/${params.directoryId}/add`}
                        >
                            add them as plain text
                        </Link>{' '}
                        to provide knowledge. This will enable Gandy to assist
                        you with your data and allow you to ask any questions
                        about the knowledge you provide.
                    </p>
                </div>
            )}
        </section>
    );
}
