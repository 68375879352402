import React, { useEffect, useState } from 'react';
import { getSlackConnection } from '../../features/slack/slack_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { Link } from 'react-router-dom';
import slackLogo from '../../img/slack_icon.svg';
import restApiIcon from '../../img/rest_api_icon.svg';

export function Integrations() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (activeService) {
            const fetchData = async () => {
                const slackConnectionStatus = await getSlackConnection(
                    { serviceId: activeService.service_id },
                    token
                );

                if (slackConnectionStatus.status === 200) {
                    setIsConnected(true);
                }
            };
            fetchData();
        }
    });

    return (
        <section className="section">
            <header className="section__header">
                <h2 className="section__title">Integrations</h2>
                <aside></aside>
            </header>

            <div className={'integrations-list'}>
                <div className={'integrations-list__item'}>
                    <div className={'integrations-list__item-logo'}>
                        <img
                            src={slackLogo}
                            height="60"
                            alt="Gandy Administration Panel"
                        />
                    </div>

                    <div className={'integrations-list__item-content'}>
                        <h3 className={'integrations-list__item-header'}>
                            Slack
                        </h3>

                        <p className={'integrations-list__item-description'}>
                            Get knowledge from your team's Slack workspace.
                        </p>

                        {isConnected ? (
                            <Link
                                to={`/slack/connect`}
                                className="btn btn_small"
                            >
                                Configure
                            </Link>
                        ) : (
                            <a href="https://slack.com/oauth/v2/authorize?client_id=7462245245889.7359818082054&scope=app_mentions:read,channels:join,chat:write,commands,im:history,links:read,reactions:read,reactions:write,team:read,users:read&user_scope=">
                                <img
                                    alt="Add to Slack"
                                    height="40"
                                    width="139"
                                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                                />
                            </a>
                        )}
                    </div>
                </div>

                <div className={'integrations-list__item'}>
                    <div className={'integrations-list__item-logo'}>
                        <img
                            src={restApiIcon}
                            height="60"
                            alt="Rest API icon"
                        />
                    </div>

                    <div className={'integrations-list__item-content'}>
                        <h3 className={'integrations-list__item-header'}>
                            RestApi
                        </h3>

                        <p className={'integrations-list__item-description'}>
                            Generate tokens for RestApi. Use them to get
                            knowledge and build own solutions
                        </p>

                        <Link to={`/rest-api`} className="btn btn_small">
                            Tokens
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
