import React, { useEffect, useState } from 'react';
import './directories_list.css';
import {
    createDirectory,
    getDirectories,
} from '../../features/directories/directories_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { directories$ } from '../../features/directories/directories_repository';
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { CiCirclePlus, CiCircleRemove } from 'react-icons/ci';
import { setCurrentPage } from '../../features/documents/documents_repository';

function DirectoriesList() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [directories] = useObservable(directories$);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDirectoryName, setNewDirectoryName] = useState('');
    const [isDirectoryCreating, setIsDirectoryCreating] = useState(false);
    const navigate = useNavigate();

    const createNewDirectory = async (event) => {
        event.preventDefault();

        setIsModalOpen(false);
        setIsDirectoryCreating(true);
        const directory = await createDirectory(
            {
                name: newDirectoryName,
                serviceId: activeService.service_id,
            },
            token
        );
        setIsDirectoryCreating(false);

        if (directory) {
            setCurrentPage(1);
            return navigate(`/directories/${directory.id}`);
        }
    };

    const showCreateNewDirectoryModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                await getDirectories(
                    {
                        serviceId: activeService.service_id,
                    },
                    token
                );
            }
        }
        fetchData();
    }, [activeService, token]);

    return (
        <article>
            <section className="section">
                <header className="section__header">
                    <h2 className="section__title">Directories</h2>
                    <aside>
                        <span
                            className={'btn-action'}
                            onClick={showCreateNewDirectoryModal}
                        >
                            <IconContext.Provider
                                value={{
                                    color: '#fff',
                                    size: '1.5rem',
                                    title: 'Add document',
                                }}
                            >
                                <CiCirclePlus aria-label="Add document" />
                            </IconContext.Provider>
                            Create new directory
                        </span>
                    </aside>
                </header>

                {directories.length <= 0 && (
                    <div className={'empty-list'}>
                        <h3>You don't have any directories yet!</h3>
                        <p>
                            To start working with Gandy.ai, you need to create
                            at least one directory to store your documents.
                            Start by clicking "Create new directory" just below
                            or in the right corner of this section.
                        </p>
                    </div>
                )}

                <ul className="directory-list">
                    {directories.map((directory) => (
                        <li key={directory.id}>
                            <Link
                                onClick={() => setCurrentPage(1)}
                                to={`/directories/${directory.id}`}
                                className="directory-list__box"
                            >
                                <h3>{directory.name}</h3>
                                <p>{directory.documents} documents</p>
                            </Link>
                        </li>
                    ))}

                    {isDirectoryCreating && (
                        <li className="directory-list__box directory-list__box_new">
                            <div className={'loader'}></div>
                        </li>
                    )}

                    <li
                        onClick={showCreateNewDirectoryModal}
                        className="directory-list__box directory-list__box_new"
                    >
                        <h3>
                            Create
                            <br />
                            new directory
                        </h3>
                    </li>
                </ul>
            </section>

            <Dialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className="relative z-50"
            >
                <div className="modal-wrapper">
                    <DialogPanel className="modal">
                        <DialogTitle className="font-bold">
                            Enter directory name
                            <span
                                className={'btn-action'}
                                onClick={() => setIsModalOpen(false)}
                            >
                                <IconContext.Provider
                                    value={{
                                        color: '#fff',
                                        size: '2rem',
                                        title: 'Close',
                                    }}
                                >
                                    <CiCircleRemove aria-label="Close" />
                                </IconContext.Provider>
                            </span>
                        </DialogTitle>
                        <Description>
                            This will create new directory
                        </Description>

                        <form
                            className="add-directory-form"
                            onSubmit={createNewDirectory}
                        >
                            <input
                                type={'text'}
                                onChange={(e) =>
                                    setNewDirectoryName(e.target.value)
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Escape') {
                                        setNewDirectoryName(e.target.value);
                                        createNewDirectory();
                                    }
                                }}
                            />
                            <button className="btn" type="submit">
                                Create new
                            </button>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
        </article>
    );
}

export default DirectoriesList;
