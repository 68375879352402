import React, { useEffect, useState } from 'react';
import { getQuestionsRaport } from '../../features/raports/questions_raport_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getDirectories } from '../../features/directories/directories_service';
import { directories$ } from '../../features/directories/directories_repository';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft, CiCircleChevRight } from 'react-icons/ci';

export function QuestionsRaport() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [questions, setQuestions] = useState([]);
    const [directories] = useObservable(directories$);
    const [date, onChange] = useState([
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const questionRaportData = await getQuestionsRaport(
                {
                    serviceId: activeService.service_id,

                    params: {
                        date_from: date[0].toISOString(),
                        date_to: date[1].toISOString(),
                        page,
                        limit,
                    },
                },
                token
            );

            setPages(questionRaportData.pages);
            setQuestions(questionRaportData.list);

            if (activeService) {
                if (directories.length <= 0) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        };

        if (activeService) {
            fetchData();
        }
    }, [activeService, token, page, limit, date, directories.length]);

    const [directoryId, setDirectoryId] = useState('');

    const handleChangePage = async (page) => {
        setPage(page);
    };

    const submitForm = async (ev) => {
        ev.preventDefault();

        const params = {
            date_from: date[0].toISOString(),
            date_to: date[1].toISOString(),
        };

        if (directoryId) {
            params.directory_id = directoryId;
        }

        const questionRaportData = await getQuestionsRaport(
            {
                serviceId: activeService.service_id,
                params,
            },
            token
        );

        setQuestions(questionRaportData.list);
    };

    return (
        <div>
            <section className="section">
                <header className="section__header">
                    <h2 className="section__title">Questions</h2>
                </header>

                <form onSubmit={(ev) => submitForm(ev)} className={'form'}>
                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'date-range'}>
                            Select date
                            <DateRangePicker
                                id={'date-range'}
                                onChange={onChange}
                                value={date}
                            />
                        </label>
                    </div>

                    <div className={'form__control form__control_inline'}>
                        <label htmlFor={'aggregate'}>
                            Directory
                            <select
                                className={'control'}
                                onChange={(ev) => {
                                    setDirectoryId(ev.target.value);
                                }}
                                id={'aggregate'}
                            >
                                <option value={''}>All</option>
                                {directories.map((directory) => (
                                    <option
                                        key={directory.id}
                                        value={directory.id}
                                    >
                                        {directory.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <button className={'btn'} type={'submit'}>
                        Show
                    </button>
                </form>

                <table className="files-table">
                    <thead>
                        <tr>
                            <td colSpan="99">
                                <form className={'form pagination-form'}>
                                    <div
                                        className={
                                            'form__control form__control_inline'
                                        }
                                    >
                                        <label htmlFor={'limit'}>
                                            Show:
                                            <select
                                                className={'control'}
                                                id={'limit'}
                                                onChange={(ev) => {
                                                    setLimit(ev.target.value);
                                                    setPage(1);
                                                }}
                                            >
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </label>
                                    </div>
                                    {page > 1 && (
                                        <div
                                            onClick={() => {
                                                handleChangePage(page - 1);
                                            }}
                                        >
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Actions',
                                                }}
                                            >
                                                <span className="btn-icon">
                                                    <CiCircleChevLeft aria-label="Previous page" />
                                                </span>
                                            </IconContext.Provider>
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'form__control form__control_inline'
                                        }
                                    >
                                        <label htmlFor={'limit'}>
                                            Page:
                                            <input
                                                className={'control'}
                                                type={'number'}
                                                value={page}
                                                max={pages}
                                                min={1}
                                                onChange={(ev) =>
                                                    setPage(ev.target.value)
                                                }
                                            />
                                        </label>
                                    </div>
                                    of {pages}
                                    {page < pages && (
                                        <div
                                            onClick={() => {
                                                handleChangePage(page + 1);
                                            }}
                                        >
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Actions',
                                                }}
                                            >
                                                <span className="btn-icon">
                                                    <CiCircleChevRight aria-label="Next page" />
                                                </span>
                                            </IconContext.Provider>
                                        </div>
                                    )}
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {questions.map((question, index) => (
                            <tr key={index}>
                                <td>{question.question}</td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line no-undef
                                        __html: marked.parse(question.answer),
                                    }}
                                ></td>

                                <td>
                                    {new Intl.DateTimeFormat('en-US').format(
                                        new Date(question.timestamp)
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan="99">
                                <form className={'form pagination-form'}>
                                    <div
                                        className={
                                            'form__control form__control_inline'
                                        }
                                    >
                                        <label htmlFor={'limit'}>
                                            Show:
                                            <select
                                                className={'control'}
                                                id={'limit'}
                                                onChange={(ev) => {
                                                    setLimit(ev.target.value);
                                                    setPage(1);
                                                }}
                                            >
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={50}>50</option>
                                            </select>
                                        </label>
                                    </div>
                                    {page > 1 && (
                                        <div
                                            onClick={() => {
                                                handleChangePage(page - 1);
                                            }}
                                        >
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Actions',
                                                }}
                                            >
                                                <span className="btn-icon">
                                                    <CiCircleChevLeft aria-label="Previous page" />
                                                </span>
                                            </IconContext.Provider>
                                        </div>
                                    )}
                                    <div
                                        className={
                                            'form__control form__control_inline'
                                        }
                                    >
                                        <label htmlFor={'limit'}>
                                            Page:
                                            <input
                                                className={'control'}
                                                type={'number'}
                                                value={page}
                                                max={pages}
                                                min={1}
                                                onChange={(ev) =>
                                                    setPage(ev.target.value)
                                                }
                                            />
                                        </label>
                                    </div>
                                    of {pages}
                                    {page < pages && (
                                        <div
                                            onClick={() => {
                                                handleChangePage(page + 1);
                                            }}
                                        >
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Actions',
                                                }}
                                            >
                                                <span className="btn-icon">
                                                    <CiCircleChevRight aria-label="Next page" />
                                                </span>
                                            </IconContext.Provider>
                                        </div>
                                    )}
                                </form>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        </div>
    );
}
