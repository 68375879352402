import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    auth,
    logInWithEmailAndPassword,
    signInWithGoogle,
} from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import './Login.css';
import Logo from './components/logo/logo';
import { LoadingScreen } from './pages/loading_screen/loading_screen';
import googleLogo from './img/google_logo.svg';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isIncorrectPasswordOrEmail, setIsIncorrectPasswordOrEmail] =
        useState(false);

    useEffect(() => {
        if (loading) {
            setIsLoading(true);
            return;
        } else {
            setIsLoading(false);
        }

        if (user) navigate('/admin');
    }, [user, loading, navigate]);

    const login = async () => {
        setIsIncorrectPasswordOrEmail(false);
        const loginSuccessful = await logInWithEmailAndPassword(
            email,
            password
        );

        if (loginSuccessful === null) {
            setIsIncorrectPasswordOrEmail(true);
        }
    };

    return !isLoading ? (
        <div className="login">
            <div className="login__container">
                <Logo />

                <form
                    className={'form form_login'}
                    onSubmit={(ev) => {
                        ev.preventDefault();
                        logInWithEmailAndPassword(email, password);
                    }}
                >
                    {isIncorrectPasswordOrEmail && (
                        <div className="login__error">
                            Incorrect email or password
                        </div>
                    )}

                    <input
                        type="email"
                        className="login__textBox"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-mail Address"
                        autoComplete={'email'}
                    />
                    <input
                        type="password"
                        className="login__textBox"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <button className="btn" onClick={login}>
                        Login
                    </button>
                    <button
                        className="btn login__google"
                        onClick={signInWithGoogle}
                    >
                        <img
                            src={googleLogo}
                            height="24"
                            width="24"
                            alt="Gandy Administration Panel"
                        />
                        Login with Google
                    </button>
                </form>
                <div>
                    <Link className={'link link_secondary'} to="/reset">
                        Forgot Password
                    </Link>
                </div>
                {/*<div>*/}
                {/*    Don't have an account?{' '}*/}
                {/*    <Link className={'link link_secondary'} to="/register">*/}
                {/*        Register*/}
                {/*    </Link>{' '}*/}
                {/*    now.*/}
                {/*</div>*/}
            </div>
        </div>
    ) : (
        <LoadingScreen />
    );
}

export default Login;
