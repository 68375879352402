import { createStore } from '@ngneat/elf';
import {
    selectAllEntities,
    setEntities,
    withEntities,
} from '@ngneat/elf-entities';

export const TOKENS_STORE_NAME = 'rest_api';

const restApiTokensStore = createStore(
    { name: TOKENS_STORE_NAME },
    withEntities({ idKey: 'token_id' })
);

export const restApiTokens$ = restApiTokensStore.pipe(selectAllEntities());

export function setRestApiTokens(tokens) {
    restApiTokensStore.update(setEntities(tokens));
}
