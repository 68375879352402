import { http } from '../requester/requester';
import { updateUser } from './user_repository';
import { logout } from '../../firebaseConfig';

export async function fetchUser(token) {
    try {
        const response = await http({
            url: 'https://api.gandy.ai/ui/userinfo',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
        }

        updateUser(response.data);
    } catch (error) {
        console.error(error);
    }
}
