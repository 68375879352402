import { http } from '../requester/requester';
import { logout } from '../../firebaseConfig';
import { setRestApiTokens } from './rest_api_repository';

export async function createToken(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/tokens`,
            method: 'post',
            headers: {
                Authorization: token,
            },
            data: {
                name: options.name,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function readTokens(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/tokens`,
            method: 'get',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200) {
            setRestApiTokens(response.data.list);
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function updateToken(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/tokens/${options.tokenId}/`,
            method: 'put',
            headers: {
                Authorization: token,
            },
            data: {
                name: options.name,
                disabled: options.isDisabled,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function disableToken(options, token) {
    options.isDisabled = true;
    await updateToken(options, token);
}

export async function enableToken(options, token) {
    options.isDisabled = false;
    await updateToken(options, token);
}

export async function removeToken(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/tokens/${options.tokenId}`,
            method: 'delete',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}
