import { http } from '../requester/requester';
import { setDirectories } from './directories_repository';
import { logout } from '../../firebaseConfig';

export async function getDirectories(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        setDirectories(response.data);
    } catch (error) {
        console.error(error);
    }
}

export async function createDirectory(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories`,
            method: 'post',
            headers: {
                Authorization: token,
            },
            data: {
                name: options.name,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function readDirectory(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}`,
            headers: {
                Authorization: token,
            },
        });

        return response.data; // todo: update documents list
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function removeDirectory(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}`,
            method: 'delete',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200;
    } catch (error) {
        console.error(error);
        return false;
    }
}
