import { createStore } from '@ngneat/elf';
import {
    getEntitiesCount,
    selectAllEntities,
    selectEntity,
    setEntities,
    withEntities,
} from '@ngneat/elf-entities';

export const DIRECTORIES_STORE_NAME = 'user';

const directoriesStore = createStore(
    { name: DIRECTORIES_STORE_NAME },
    withEntities()
);

export const directories$ = directoriesStore.pipe(selectAllEntities());
export const hasDirectories = directoriesStore.query(getEntitiesCount()) > 0;

export function getDirectory(directoryId) {
    return directoriesStore.pipe(selectEntity(directoryId));
}

export function setDirectories(directories) {
    directoriesStore.update(setEntities(directories.list));
}
