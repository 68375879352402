import { http } from '../requester/requester';
import {
    setActiveDocument,
    setDocuments,
    setIsLoading,
} from './documents_repository';
import { logout } from '../../firebaseConfig';

export async function getDocuments(options, token) {
    try {
        const params = new URLSearchParams();

        for (const key in options.params) {
            params.append(key, options.params[key]);
        }

        let paramsString = '';
        if (params.size > 0) {
            paramsString = `?${params.toString()}`;
        }

        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents${paramsString}`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        setDocuments(response.data);
    } catch (error) {
        console.error(error);
    }
}

export async function createDocument(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents`,
            method: 'post',
            headers: {
                Authorization: token,
            },
            data: {
                name: options.name,
                url: options.url,
                content: options.content,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function updateDocument(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents/${options.documentId}`,
            method: 'put',
            headers: {
                Authorization: token,
            },
            data: {
                name: options.name,
                url: options.url,
                content: options.content,
                mimetype: options.mimetype,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function createDocumentFromCrawl(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents/crawl?url=${options.url}`,
            method: 'post',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function uploadDocument(options, token) {
    const fileData = new FormData();
    fileData.append('file', options.data);

    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents/upload`,
            method: 'post',
            headers: {
                Authorization: token,
            },
            data: fileData,
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function readDocument(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents/${options.documentId}`,
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        setActiveDocument(response.data);

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setIsLoading(false);
    }
}

export async function removeDocument(options, token) {
    try {
        const response = await http({
            url: `https://api.gandy.ai/ui/${options.serviceId}/directories/${options.directoryId}/documents/${options.documentId}`,
            method: 'delete',
            headers: {
                Authorization: token,
            },
        });

        if (response.status === 401) {
            logout();
            return;
        }

        return response.status === 200 && response.data;
    } catch (error) {
        console.error(error);
        return false;
    }
}
