import React from 'react';
import './logo.css';
import gandyLogo from '../../img/gandy_logo.svg';
import { Link } from 'react-router-dom';

function Logo() {
    return (
        <Link className={'logo-link'} to={`/`}>
            <figure className="logo">
                <img
                    src={gandyLogo}
                    height="60"
                    alt="Gandy Administration Panel"
                />
                Gandy.ai
            </figure>
        </Link>
    );
}

export default Logo;
