import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import React, { useEffect } from 'react';
import { updateToken } from '../features/auth/auth_repository';
import { useObservable } from '@ngneat/react-rxjs';
import { user$ } from '../features/user/user_repository';
import MainNav from '../components/main_nav/main_nav';
import TopNav from '../components/top_nav/top_nav';
import { LoadingScreen } from '../pages/loading_screen/loading_screen';
import { isLoading$ } from '../features/global_loader/global_loader_service';
import { setIsLoading } from '../features/documents/documents_repository';

export const ProtectedRoute = ({ children }) => {
    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    const [userData] = useObservable(user$);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [$user] = useObservable(user$);
    const [$isLoading] = useObservable(isLoading$);

    useEffect(() => {
        setIsLoading(true);
    }, [location]);

    useEffect(() => {
        if (loading) return;

        if (!user) {
            if (location.pathname === '/slack/connect') {
                const slackToken = searchParams.get('t');
                localStorage.setItem('slackToken', slackToken);
            }

            return navigate('/');
        }

        updateToken(user.accessToken);

        if (userData.email) {
            if (userData.services.length === 0) {
                return navigate('/wizard');
            }
        }

        const slackToken = localStorage.getItem('slackToken');
        if (slackToken) {
            localStorage.removeItem('slackToken');
            return navigate(`/slack/connect?t=${slackToken}`);
        }

        document.querySelector('.main-nav')?.classList.remove('show');
    }, [user, loading, userData, navigate, searchParams]);

    return $user.email ? (
        <>
            <MainNav />

            <main>
                <TopNav />

                {children}
            </main>

            {$isLoading && (
                <div className={'global-loader'}>
                    <div className={'loader'}></div>
                </div>
            )}
        </>
    ) : (
        <LoadingScreen />
    );
};

export default ProtectedRoute;
