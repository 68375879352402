import { initializeApp } from 'firebase/app';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import { EMPTY_USER, updateUser } from './features/user/user_repository';

const firebaseConfig = {
    apiKey: 'AIzaSyDspnnWnNszuGK0p8vXLgZ_LDdutdRTXJ8',
    //    authDomain: 'prime-framing-422717-q1.firebaseapp.com',
    authDomain: 'auth.gandy.ai',
    projectId: 'prime-framing-422717-q1',
    storageBucket: 'prime-framing-422717-q1.appspot.com',
    messagingSenderId: '463117735121',
    appId: '1:463117735121:web:f36521d7add886df3721f0',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        await signInWithPopup(auth, googleProvider);
    } catch (err) {
        console.error(err);
        // alert(err.message);
    }
};
const logInWithEmailAndPassword = async (email, password) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        return null;
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        // alert(err.message);
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset link sent!');
    } catch (err) {
        console.error(err);
        // alert(err.message);
    }
};
const logout = () => {
    updateUser(EMPTY_USER);
    signOut(auth);
};
export {
    auth,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};
