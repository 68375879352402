import { createStore, select, withProps } from '@ngneat/elf';

export const LOADER_STORE_NAME = 'loader';

const loaderStore = createStore(
    { name: LOADER_STORE_NAME },
    withProps({ isLoading: false })
);

export const isLoading$ = loaderStore.pipe(select((state) => state.isLoading));

export function updateIsLoading(isLoading) {
    loaderStore.update(() => ({
        isLoading,
    }));
}
